.lssl-footer {
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.lssl-footer .ant-divider-horizontal {
    margin: 0;
    padding-bottom: 0;
}

.lssl-footer footer {
	background: #212529;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-family: arial;
    padding: 15px;
}
.lssl-footer .footerLinks {
   text-align: center;
}
.lssl-footer .footerLinks ul {
	padding: 0;
    list-style-type: none;
    margin: 0;
}
.lssl-footer .footerLinks li {
	display: inline;
}
.lssl-footer .footerLinks a {
	color: #d9d9d9;
	text-decoration: none;
	font-size: 13px;
}
.lssl-footer .copyright {
    text-align: center;
}

.lssl-footer .copyright p {
	margin: 0;
	color: #b3b3b3;
	font-size: 11px;
}
